@import './theme.scss';

$sider-width: 200px;
$app-bar-height: 64px;

.hide {
  display: none;
}

.ant-dropdown-menu-title-content {
  .link-button {
    color: rgb(0, 40, 92) !important;
  }
}

// Sider
.main-sider {
  transition: all 0s !important;
  height: 100vh;
  position: fixed;
  left: 0;

  * {
    transition: all 0s !important;
  }

  .ant-card {
    &-bordered {
      border-width: 1.5px; // this may be too faint to notice, the idea was for a faint border between content background and greyed out sider pieces
    }

    &-body {
      padding: 18px 12px;
      background-color: $ant-background-gray;
    }
  }

  .impersonating-business-contact {
    font-size: 0.75rem;
    color: black;

    .ant-card-body {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .ant-layout {
    height: 100%;

    &-header {
      background-color: white;
      padding: 0 10px;
      text-align: center;

      a {
        color: white;
      }
    }

    &-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;

      .ant-menu-submenu {
        // color: rgb(0, 40, 92) !important;
        color: #757575;
      }
      .ant-menu-submenu:hover,
      .ant-menu-submenu-title:hover,
      .ant-menu-title-content:hover,
      .ant-menu-submenu-arrow:hover,
      .ant-menu-submenu:hover
        > .ant-menu-submenu-title
        > .ant-menu-submenu-expand-icon,
      .ant-menu-submenu:hover
        > .ant-menu-submenu-title
        > .ant-menu-submenu-arrow {
        color: rgb(0, 40, 92) !important;
      }
      .ant-menu-submenu:active,
      .ant-menu-submenu-title:active {
        background: rgba(0, 40, 92, 0.05);
      }
      .ant-menu-submenu::selection {
        background: rgba(0, 40, 92, 0.05);
      }
      .ant-menu-title-content::selection {
        background: rgba(0, 40, 92, 0.05);
      }

      .ant-menu {
        height: 100%;

        .ant-menu-item {
          color: rgb(0, 40, 92) !important;
          &::after {
            border-right-color: $primary;
          }

          &:active {
            background-color: $yellow-active;
          }

          &:hover {
            color: $primary;
          }

          a,
          a:link,
          a:visited,
          .link-button {
            color: $nav-gray-font;
          }

          a:focus,
          a:hover,
          .link-button:hover {
            text-decoration: none;
            color: $primary;
          }

          &-selected {
            background: $yellow-active;
            color: $primary;

            a,
            a:link,
            a:visited {
              color: $primary;
            }

            &::after {
              border-right-color: $primary;
            }
          }
        }

        .link-button {
          width: 100%;
          text-align: left;
        }
      }
    }

    &-footer {
      padding: 0;

      .sidebar-social-icons {
        font-size: 1.75rem;
      }

      .terms {
        font-size: 0.875rem;
      }

      .copyright {
        font-size: 0.625rem;
      }
    }

    // mobile sider view
    // hamburger tab that comes with sider component that we are using for desktop only
    &-sider-zero-width-trigger {
      display: none;
    }
  }

  .ant-drawer-content-wrapper,
  .ant-drawer-content {
    width: $sider-width !important;
  }

  .ant-drawer-body {
    height: 100%;
    padding: 0;
    width: $sider-width;

    .ant-layout {
      height: 100%;
      margin-top: 0;
    }
  }
}

.ant-layout-header {
  .anticon-caret-down {
    margin-left: 5px;
  }
}

// context wise div works with sider, but technically it is absolutely positioned outside of sider - contains impersonate/signout dropdown list
.user-dropdown-menu {
  .ant-dropdown-menu-item-active {
    background: none;
  }

  a,
  button {
    &:hover {
      text-decoration: none;
    }
    .anticon {
      padding-right: 5px;
    }
  }

  .ant-dropdown-menu-item > button:hover,
  .ant-dropdown-menu-submenu-title > button:hover {
    color: rgba(0, 0, 0, 0.65); //color from antd
    text-decoration: none;
  }
}

.ant-dropdown-menu {
  .anticon.anticon-usergroup-add.link-icon-sidebar {
    margin-right: 0px !important;
  }
}

.link-icon-sidebar {
  margin-right: 0;
  min-width: auto;
  font-size: 14px;
  vertical-align: -0.125em;
}

.menu-link-icon {
  .anticon {
    padding-right: 5px;
  }
}

.main-section {
  min-height: 100vh;

  @media (min-width: $desktop-min) {
    margin-left: $sider-width;
  }

  // AppBar
  .main-header {
    background-color: $secondary;
    padding: 0 35px;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;

    @media (max-width: $mobile-max) {
      padding: 0 15px;
    }

    @media (min-width: $desktop-min) {
      left: $sider-width;
    }

    .main-app-bar {
      color: white;
      height: 100%;

      & > div {
        height: 100%;
      }

      .left {
        font-size: 1.25rem;

        .link-button {
          color: white;
        }
      }

      .middle {
        text-align: center;

        a,
        a:link,
        a:visited {
          color: white;
        }

        img {
          width: auto;
          height: $app-bar-height;

          @media (max-width: $mobile-max) {
            height: calc($app-bar-height * 3 / 4);
          }
        }
      }

      .right {
        text-align: right;

        a {
          padding: 2.5px;

          &.alert-action {
            color: $danger;
          }

          &.alert-action:active {
            color: $danger-dark;
          }
        }
      }

      .material-icons {
        vertical-align: middle;
      }
    }
  }

  // Content
  .main-content {
    margin: 15px;
    margin-top: $app-bar-height + 15px;
    height: 100%;

    & > div {
      height: 100%;
    }

    @media (min-width: $desktop-min) {
      min-width: 840px;
    }
  }
}

.ant-drawer-body > :first-child {
  margin-top: 35px;
}

/* Site loading animation */
.loading-container {
  height: 100vh;
  width: 100vw;
  z-index: 1;
  position: fixed;
  overflow-x: hidden;
  .loading-content {
    position: relative;
    text-align: center;
    top: 45%;
  }
}

.ant-spin {
  color: $primary;
  .ant-spin-dot {
    .ant-spin-dot-item {
      background-color: $primary;
    }
  }
}

.show-on-mobile {
  @media (max-width: $mobile-max) {
    display: block;
  }

  @media (min-width: $desktop-min) {
    display: none;
  }
}

.hide-on-mobile {
  @media (max-width: $mobile-max) {
    display: none;
  }

  @media (min-width: $desktop-min) {
    display: block;
  }
}

// Page Sized Drawer
.full-width-drawer {
  .ant-drawer-header {
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .ant-drawer-body {
    margin-top: 40px;
  }
}

.switch-container {
  padding-top: 3px;
  padding-bottom: 3px;
  display: block;
  width: 100%;
  .switch-label {
    margin-left: 5px;
    font-size: 'small';
  }
}
