@import './theme.scss';

// shared
.autocomplete {
  width: 200px;
  top: 32px;
  left: 0;
  width: 100%;
}

.autocomplete-container {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  z-index: 1050;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.page-card {
  // currently on every page's main card, might be creating too much white space
  height: 100%;

  .ant-card-head {
    color: $secondary;
  }
}

.ant-menu-title-content {
  .anticon {
    padding-right: 15px;
  }
}

// impersonate
.filters-card {
  .ant-card-head {
    color: $secondary;
  }
}
// dashboard
.dashboard-controls-wrapper,
.dashboard-controls,
.dashboard-spacer {
  display: none;
}

.dashboard-controls {
  .edit-icon {
    cursor: pointer;
  }
}

.sortable-card {
  .overlay {
    position: absolute;
    top: 7.5px;
    bottom: 7.5px;
    left: 7.5px;
    right: 7.5px;
    opacity: 0.5;
    background: white;
  }

  .trash {
    position: absolute;
    right: 7.5px;
    top: 7.5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background: white;
    cursor: pointer;
  }

  .handle {
    position: absolute;
    margin: auto;
    left: 7.5px;
    right: 7.5px;
    top: 7.5px;
    bottom: 7.5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background: white;
    cursor: pointer;
  }

  .avatar-icon {
    vertical-align: middle;
  }
}

@media (max-width: $mobile-max) {
  .sortable-card {
    width: 100%;
  }
}

.user-select-none {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.edit-popover {
  &-checklist {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    height: 350px;

    label:first-child {
      margin-left: 8px;
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    width: 300px;
  }
}

@media (min-width: $desktop-min) {
  .dashboard-controls-wrapper {
    display: block;
  }
  .dashboard-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      margin-right: 25px;
    }
    .link-button {
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 3px;
      padding: 3px;
      border-radius: 4px;
      outline: none;

      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 0.07);
      }
    }
    .edit-icon {
      margin-left: 5px;
      margin-top: 3px;
      padding: 3px;
      border-radius: 4px;

      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 0.07);
      }
    }
  }
  .dashboard-spacer {
    display: block;
  }

  .dashboard-site-view {
    width: 25%;
  }
}

// support page

// invoice page
.mobile-cards {
  display: block;
}
.load-more {
  margin-top: 25px;
  width: 100%;
}
.ant-calendar-picker {
  width: 100%;
}
.invoices-filters {
  button {
    width: 100%;
  }
}
.invoice-table-tags-container {
  min-height: auto;
  padding: 10px 20px 20px 20px;
}
.table-tags-container {
  min-height: auto;
  padding: 20px;
}
.export-to-csv {
  float: right;
}

// projects page
.mobile-table-head {
  background-color: $dashboard-white;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -24px;
  padding: 20px;
  border-bottom: 1px solid $gainsboro;
  .project-number {
    font-size: 1.5rem;
    color: $primary;
  }
  .project-work-site {
    font-size: 0.8125rem;
    text-transform: uppercase;
    color: $dark-gray;
    font-weight: 400;
  }
}
.active-project {
  border-left: 5px solid $primary;
}
.closed-project {
  border-left: 5px solid $black;
}
.mobile-table-fields {
  padding-top: 10px;
}
.ant-table-summary {
  font-weight: bold;
}

.mobile-table-metrics {
  .mobile-table-metric {
    display: table;
    width: 100%;
    margin-top: 15px;
    .metric-label {
      display: table-cell;
      font-size: 1rem;
      color: $dark-slate-gray;
    }
    .metric-value {
      display: table-cell;
      text-align: right;
      font-size: 1rem;
      color: $black;
    }
  }
}
.project-table-link {
  color: rgba(0, 0, 0, 0.65) !important;
}
.project-table-link:hover {
  text-decoration: none;
}

.mobile-table-metrics-totals {
  background-color: #ffffdc;
  padding: 5px;
  margin-top: 15px;

  & div:first-child {
    margin-top: 5px;
  }
}

.mobile-table-metrics-last-date {
  background-color: #ddf3ff;
  padding: 5px;
  margin-top: 15px;

  & div:first-child {
    margin-top: 5px;
  }
}

// project cards
.project-cards-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 0;
  background: #f0f2f5;
  padding: 20px;
  min-height: 100vh;
}

.card-view-card {
  .ant-card-head-title {
    color: #002b5c;
  }
}
.card-view-yellow {
  background-color: #ffffdc;
  padding: 2px;
}
.card-view-blue {
  background-color: #ddf3ff;
  padding: 2px;
}

.fullscreen-settings {
  position: absolute;
  top: -35px;
  right: 25px;
  font-size: 28px;
  z-index: 100000;
  color: $primary;
}

.ant-dropdown-menu-item:hover {
  .link-button {
    color: rgba(0, 0, 0, 0.65);
    text-decoration: none;
  }
}

// what's new page
.release-note {
  ul {
    margin-bottom: 10px;
  }
}

@media (min-width: $desktop-min) {
  .invoice-table,
  .project-table {
    display: block;
  }
  .mobile-cards {
    display: none;
  }
  .invoices-filters {
    button {
      width: auto;
    }
  }
  .table-tags-container {
    min-height: 46px;
  }
}

//landing page

.landing-section {
  height: 100vh;

  .landing-header {
    background-color: $secondary;
    padding: 0 35px;

    @media (max-width: $mobile-max) {
      padding: 0 15px;
    }

    .landing-app-bar {
      color: white;
      height: 100%;

      & > div {
        height: 100%;
      }

      .left {
        font-size: 1.25rem;

        .link-button {
          color: white;
        }
      }

      .middle {
        text-align: center;

        a,
        a:link,
        a:visited {
          color: white;
        }

        img {
          width: auto;
          height: $app-bar-height;

          @media (max-width: $mobile-max) {
            height: calc($app-bar-height * 3 / 4);
          }
        }
      }

      .right {
        text-align: right;
      }
    }
  }

  .landing-content {
    background-image: url('./welder.jpg');
    background-position: center;
    background-size: cover;
    background-blend-mode: luminosity;
    background-color: $secondary;
    height: 100%;

    .landing-message {
      font-size: 3em;
      line-height: 0.75;
      color: white;

      @media (max-width: $mobile-max) {
        line-height: 1.25;
        margin-bottom: 0.3em;
      }
    }
  }

  .landing-call-to-action {
    text-align: center;

    button {
      @media (min-width: $desktop-min) {
        font-size: 2em;
        height: 60px;
      }
    }
  }

  .signup-spacer {
    .ant-space-item {
      justify-content: center;
      display: flex;
    }
    form {
      width: 100%;
    }
    label,
    p,
    h1 {
      color: white;
    }
  }
}
.landing-footer {
  .left {
    .landing-social-icons {
      font-size: 3.5em;
      max-width: 350px;

      a {
        color: rgb(0, 40, 92);
      }

      @media (max-width: $mobile-max) {
        font-size: 2.625em;
        margin: auto;
      }
    }

    .landing-terms {
      font-size: 1.25em;
      max-width: 350px;

      a {
        color: rgb(0, 40, 92);
      }

      @media (max-width: $mobile-max) {
        font-size: 1em;
        margin: auto;
      }
    }

    @media (max-width: $mobile-max) {
      text-align: center;
    }
  }

  .right {
    margin-top: auto;
    text-align: right;

    p {
      margin: 0;
    }

    @media (max-width: $mobile-max) {
      text-align: center;
    }
  }
}

.landing-menu {
  .ant-row {
    margin-top: 5px;
  }

  .ant-menu {
    margin-top: 10px;
    font-size: 1.15em;
  }

  img {
    width: auto;
    height: $app-bar-height;
    margin-left: 11px;

    @media (max-width: $mobile-max) {
      height: calc($app-bar-height * 3 / 4);
    }
  }
}

.link-small {
  color: rgb(0, 40, 92) !important;
}

.link-small:hover {
  color: rgb(0, 40, 92) !important;
}
