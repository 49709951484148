@import './theme.scss';

.table-card {
  .ant-card-body {
    padding: 0;
  }
}

.ant-table-pagination {
  margin-right: 20px !important;
}

.ant-table-thead > tr > th {
  font-size: 0.75rem;
  white-space: nowrap;
  padding: 16px 20px 16px 24px;
}

.ant-table-tbody > tr > td,
.ant-table-summary .ant-table-cell {
  font-size: 0.8125rem;
  padding: 16px 20px 16px 24px;
}

.ant-table-tbody > tr.highlighted {
  background-color: $yellow-active;
}

.table-filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
  .table-filter {
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-block;
  }
  .filters-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-flow: wrap-reverse;
    .search-button {
      margin-right: 5px;
    }
  }
  .reset-visibility {
    margin: 10px 0;
  }
}

.report-buttons {
  text-align: right;
  button {
    margin: 5px;
  }
}

.report-table {
  .ant-divider {
    margin: 18px 0 !important;
  }

  h1 {
    font-size: 1.5rem !important;
    margin-bottom: 0 !important;
  }
}

.report-filters {
  .ant-checkbox-group-item,
  .ant-checkbox-wrapper {
    display: block;
    margin-right: 0;
  }

  .ant-form-item-label label {
    font-size: 1rem;
    font-weight: 500;
  }
}

th.table-actions.ant-table-cell {
  // hide text for screen reader only
  color: transparent;
  user-select: none;
}

th.ant-table-cell.groupless-column {
  vertical-align: bottom;

  & .anticon-search {
    top: 76%;
  }
}

.ant-table-cell.ant-table-cell-ellipsis {
  max-width: 175px;
}

.table-actions {
  text-align: center;

  & > div {
    max-width: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span {
    font-size: 17px;
    margin-top: 0 !important;
  }
}

tr.table-active-project > td.table-actions {
  border-left: solid $primary 3px;
}

tr.table-closed-project > td.table-actions {
  border-left: solid black 3px;
}

// 5 most readable color choices from research
th {
  &.table-group-gold.ant-table-cell {
    // 1
    background: #fff8dc;

    &.ant-table-column-sort {
      // 2
      background: #fff5cd;
    }

    &:hover {
      // 3
      background: #fff2bf;

      & .ant-table-filter-trigger-container {
        // 2
        background: #fff5cd;
      }
    }

    & .ant-table-filter-trigger-container:hover {
      // 4
      background: #f2e6b2 !important;
    }
  }

  &.table-group-yellow.ant-table-cell {
    // 1
    background: #ffffdc;

    &.ant-table-column-sort {
      // 2
      background: #ffffcd;
    }

    &:hover {
      // 3
      background: #ffffbf;

      & .ant-table-filter-trigger-container {
        // 2
        background: #ffffcd;
      }
    }

    & .ant-table-filter-trigger-container:hover {
      // 4
      background: #f2f2b2 !important;
    }
  }

  &.table-group-cyan.ant-table-cell {
    // white-space: normal;

    // 1
    background: #dafaf6;

    &.ant-table-column-sort {
      // 2
      background: #cbf8f2;
    }

    &:hover {
      // 3
      background: #bcf6ee;

      & .ant-table-filter-trigger-container {
        // 2
        background: #cbf8f2;
      }
    }

    & .ant-table-filter-trigger-container:hover {
      // 4
      background: #b0e9e1 !important;
    }
  }

  &.table-group-blue.ant-table-cell {
    // white-space: normal;

    // 1
    background: #ddf3ff;

    &.ant-table-column-sort {
      // 2
      background: #cfeeff;
    }

    &:hover {
      // 3
      background: #c1e9ff;

      & .ant-table-filter-trigger-container {
        // 2
        background: #cfeeff;
      }
    }

    & .ant-table-filter-trigger-container:hover {
      // 4
      background: #b4ddf2 !important;
    }
  }
}
