@import './theme.scss';

@import './_structure.scss';
@import './_cards.scss';
@import './_pages.scss';
@import './_table.scss';

// /* Default and Mobile styles */

html {
  --antd-wave-shadow-color: $primary;
}

html,
body,
#root,
#root > div {
  height: 100%;
  width: 100%;
}

a,
a:link,
a:visited {
  color: $primary;
}

a:focus,
a:hover {
  text-decoration: underline;
}

a:focus,
a:active {
  color: $primary-dark;
}

.ant-breadcrumb .ant-breadcrumb-link a:hover {
  color: $primary;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:visited {
  background-color: $primary;
  border-color: $primary;
  color: white;
}

.ant-btn-primary:focus,
.ant-btn-primary:active {
  background-color: $primary-dark;
  border-color: $primary-dark;
  color: white;
}

.ant-btn-danger,
.ant-btn-danger:hover,
.ant-btn-danger:visited {
  background-color: $danger;
  border-color: $danger;
  color: white;
}

.ant-btn-danger:focus,
.ant-btn-danger:active {
  background-color: $danger-dark;
  border-color: $danger-dark;
  color: white;
}

.ant-btn-link,
.ant-btn-link:hover,
.ant-btn-link:visited {
  color: $primary;
}

.ant-btn-link:focus,
.ant-btn-link:active {
  color: $primary-dark;
}

.link-button,
.action {
  color: $primary;
  display: inline;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
.link-button:hover {
  text-decoration: underline;
  color: $primary;
}
.action:hover {
  text-decoration: none;
  color: $primary;
}
.link-button:active,
.link-button:focus,
.action:focus,
.action:active {
  color: $primary-dark;
}

.red-text {
  color: $danger;
}

.desktop-only {
  display: none !important;

  @media (min-width: $desktop-min) {
    display: initial !important;
  }
}

.mobile-only {
  display: initial !important;

  @media (min-width: $desktop-min) {
    display: none !important;

    &.keepbuildbox {
      display: initial !important;
      visibility: hidden !important;
    }
  }
}

@media (max-width: $desktop-min) {
  .notifications .part {
    display: none !important;
    &.visible {
      display: initial !important;
    }
  }
}

.work-history-report {
  .ant-card-body {
    padding: 0;
  }
  .work-history-date-item {
    border-bottom: 1px solid $gainsboro;
    .date-header,
    .date-body {
      padding: 14px;
    }
    .date-header {
      background-color: $ant-background-gray;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: $desktop-min) {
        flex-direction: row;
        justify-content: space-around;
        .date {
          margin-bottom: 0 !important;
        }
      }
      .weekday {
        font-size: 16px;
      }
      .date {
        font-size: 13px;
        margin: 5px;
        margin-top: 2px;
      }
      .hours {
        border: 1px solid $dark-gray;
        padding: 3px 5px;
        border-radius: 3px;
        font-size: 14px;
      }
    }
  }
}

@media (min-width: $desktop-min) {
  .detail {
    & > .ant-space-item:nth-child(2) {
      display: none;
    }
  }
}

@media (max-width: $desktop-min) {
  .schedules,
  .notifications {
    .section {
      display: none !important;
      &.visible {
        display: initial !important;
      }
    }
  }
}

/* For very small screens */
@media (max-width: 576px) {
  .shift-preference-table {
    th,
    td {
      padding: 2px !important;
    }
  }

  .preferred-schedule-radio-group {
    display: flex;
    flex-direction: column;
    label {
      width: 100% !important;
      border-radius: 0px !important;
    }
  }
  .work-history-report {
    .work-record {
      padding-left: 25px;
      &:not(:last-child) {
        border-bottom: 1px solid $light-gray-font;
      }
      .ant-col:last-child {
        text-align: right;
        padding-right: 15px;
      }
    }
  }
}

a[href^='tel:'] {
  display: block;
  margin-bottom: 20px;
  color: $black;
  .anticon-phone {
    margin-right: 10px;
    color: $primary;
  }
}
